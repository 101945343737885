import React, { Suspense, lazy } from "react";
import SessionProvider from "./Components/Providers/Session";
import LoadingScreen from "./Components/Misc/LoadingScreen";
import GlobalMessage from "./Components/Misc/GlobalMessage";
import { createRoot } from "react-dom/client";

import "./index.scss";

const App = lazy(() => import("./App"));

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingScreen />}>
      <GlobalMessage />
      <SessionProvider>
        <App />
      </SessionProvider>
    </Suspense>
  </React.StrictMode>,
);
