import React, { useEffect, useState } from "react";

import close from "../../../assets/close.svg";
import checkmark from "../../../assets/checkmark.svg";

import "./GlobalMessage.scss";

const GlobalMessage = () => {
  const [message, setMessage] = useState<{ type?: "ERROR"; text: string }>({
    text: "",
  });

  useEffect(() => {
    const messageListener = (e: CustomEvent) => {
      const globalMessage = document.getElementById("global-message");
      if (globalMessage) {
        setMessage({ text: e.detail.message, type: e.detail.type });

        // Briefly reveal
        globalMessage.classList.remove("hidden");
        setTimeout(() => {
          globalMessage.classList.add("hidden");
        }, 3000);
      }
    };
    window.addEventListener("globalmessage", messageListener as EventListener);
    return () => {
      window.removeEventListener(
        "globalmessage",
        messageListener as EventListener,
      );
    };
  }, []);

  return (
    <div
      id="global-message"
      className="hidden"
      style={{
        backgroundColor:
          message.type === "ERROR" ? "var(--red)" : "var(--blue)",
      }}
    >
      <img
        src={message.type === "ERROR" ? close : checkmark}
        alt=""
        className="icon"
      />
      <span>{message.text}</span>
    </div>
  );
};

export default GlobalMessage;
