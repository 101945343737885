import React, {
  ReactNode,
  createContext,
  lazy,
  useEffect,
  useState,
} from "react";
import type { AxiosError } from "axios";
import { FrontendApi, Configuration, type Session } from "@ory/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingPage from "../LandingPage";
import LoadingScreen from "../Misc/LoadingScreen";

const ToolsPage = lazy(() => import("../ToolsPage"));

const frontend = new FrontendApi(
  new Configuration({
    basePath: "/api/.ory",
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export const SessionContext = createContext<{
  session?: Session;
  logoutUrl?: string;
  isTutor?: boolean;
  displayName?: string;
}>({});

const SessionProvider: React.FC<Props> = ({ children }) => {
  const [session, setSession] = useState<Session>();
  const [logoutUrl, setLogoutUrl] = useState<string>();
  const [error, setError] = useState<{ error: string; data: unknown }>();

  const isTutor =
    Boolean(process.env.REACT_APP_ALL_TUTOR) ||
    (session?.identity?.metadata_public as any).isTutor;

  const displayName = session?.identity?.traits.displayName;
  useEffect(() => {
    if (session || error) {
      return;
    }
    frontend
      .toSession()
      .then(async ({ data: currentSession }) => {
        setSession(currentSession);

        const { data } = await frontend.createBrowserLogoutFlow();
        setLogoutUrl(data.logout_url);
      })
      .catch((err: AxiosError) => {
        setError({
          error: err.toString(),
          data: err.response?.data,
        });
      });
  }, [session, error]);

  if (
    !session &&
    !error &&
    // Somewhat janky workaround (but not critical)
    document.referrer.startsWith(`${window.location.origin}/api/.ory/ui/login`)
  ) {
    return <LoadingScreen />;
  }

  return session ? (
    <SessionContext.Provider
      value={{ session, logoutUrl, isTutor, displayName }}
    >
      {children}
    </SessionContext.Provider>
  ) : (
    <Router>
      <Routes>
        <Route path="*" element={<LandingPage />} />

        <Route path="/tools" element={<ToolsPage />} />
      </Routes>
    </Router>
  );
};

interface Props {
  children: ReactNode;
}

export default SessionProvider;
