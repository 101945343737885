import React, { useState, useEffect } from "react";
import throttle from "lodash.throttle";

import { MODULES } from "./resources";

import { ReactComponent as TutorView } from "./assets/tutor-view.svg";
import { ReactComponent as StudentView } from "./assets/student-view.svg";
import shield from "./assets/shield.svg";
import flower from "./assets/flower.svg";
import gradCap from "./assets/grad-cap.svg";
import wave from "./assets/wave.svg";
import cake from "./assets/cake.svg";
import playing from "./assets/playing.svg";
import topDown from "./assets/top-down.svg";
import laptopConnection from "./assets/laptop-connection.svg";

import tutorVid from "./assets/tutor2.webp";
import studentVid from "./assets/student3.webp";
import selfPiano from "./assets/selfpiano.webp";
import peerPiano from "./assets/peerpiano.webp";

import mic from "../../assets/mic.svg";
import piano from "../../assets/piano.svg";
import darkLogoRed from "../../assets/dark-logo-red.svg";
import darkLogoBlue from "../../assets/dark-logo-blue.svg";

import "./LandingPage.scss";

const LandingPage: React.FC = () => {
  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const revealElement = (observer: IntersectionObserver, el: HTMLElement) => {
      el.classList.remove("hidden");
      observer.unobserve(el);
    };

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          revealElement(observer, entry.target as HTMLElement);
        }
      },
      { threshold: 0.4 },
    );

    document.querySelectorAll("#landing-page .hidden").forEach((el) => {
      observer.observe(el);

      // Manually check if the elements are already in view
      if (el.getBoundingClientRect().top < viewport.height * 0.9) {
        revealElement(observer, el as HTMLElement);
      }
    });

    const throttledResizeHandler = throttle(() => {
      setViewport({ width: window.innerWidth, height: window.innerHeight });
    }, 200);
    window.addEventListener("resize", throttledResizeHandler);

    return () => {
      document.querySelectorAll("#landing-page .hidden").forEach((el) => {
        observer.unobserve(el);
      });

      observer.disconnect();
      window.removeEventListener("resize", throttledResizeHandler);
    };
  }, []);

  const circleRadius = Math.max(viewport.height * 0.3, 225);

  return (
    <div id="landing-page">
      <div id="landing-header" className="hidden">
        <a
          href="/api/.ory/self-service/registration/browser"
          className="light bordered button-like small-text"
        >
          Get started
        </a>
        <a
          href="/api/.ory/self-service/login/browser"
          className="light bordered button-like small-text"
        >
          Sign in
        </a>
      </div>
      <div id="intro">
        <div id="intro-text">
          <h1 className="title-text">One platform for remote piano tutoring</h1>
          <p className="script">
            <span className="red logo">
              Keyboard Connect <img src={darkLogoRed} alt="" />{" "}
            </span>
            is video conferencing for piano tutors. Improve your remote lessons
            by giving students a focused, engaging experience.
          </p>
          <a
            href="/api/.ory/self-service/registration/browser"
            className="button-like big-text"
          >
            Get started
          </a>
        </div>
        <div id="intro-graphic" className="hidden">
          <div id="circle"></div>
          {MODULES.map((module, i) => (
            <div
              className="intro-module"
              key={module.title}
              style={{
                left:
                  circleRadius +
                  circleRadius *
                    Math.cos((i / MODULES.length) * Math.PI + Math.PI * 0.585),

                top: (circleRadius * 2 * i) / (MODULES.length - 1),
              }}
            >
              <img alt="" src={module.icon} />
              <span className="med-text">
                {module.title}
                {module.comingSoon || module.later ? "*" : ""}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div id="demo-wrapper" className="hidden">
        <div id="tutor-view">
          <span className="handwritten red">For piano tutors...</span>
          <TutorView />
          <img
            src={tutorVid}
            alt="Tutor watching student perform on piano"
            className="tutorVid"
          />
          <img
            src={studentVid}
            alt="Student performing on piano for tutor to watch"
            className="studentVid"
          />
          <img src={peerPiano} alt="" className="peerPianoVid" />
        </div>
        <div id="student-view">
          <span className="handwritten blue">...and your students</span>
          <StudentView />
          <img
            src={tutorVid}
            alt="Tutor watching student perform on piano"
            className="tutorVid"
          />
          <img
            src={studentVid}
            alt="Student performing on piano for tutor to watch"
            className="studentVid"
          />
          <img src={selfPiano} alt="" className="selfPianoVid" />
        </div>
        <div id="encrypted-wrapper">
          <div>
            <img src={shield} alt="" />
            <span className="blue">P2P Encrypted Transmission</span>
          </div>
        </div>
      </div>

      <div id="features-wrapper" className="hidden pane">
        <div className="for-tutors">
          <span className="handwritten red">For piano tutors...</span>
          <img src={gradCap} alt="" />
          <p className="med-text">
            Retain students by providing a better lesson experience
          </p>
        </div>
        <div className="for-students">
          <span className="handwritten blue">...and your students</span>
          <img src={flower} alt="" />
          <p className="med-text">
            Improve lesson engagement to accelerate learning outcomes
          </p>
        </div>

        <p className="light handwritten question">
          How does{" "}
          <span className="blue logo">
            <u>Keyboard Connect</u> <img src={darkLogoBlue} />
          </span>{" "}
          enhance{" "}
          <span className="red">
            <u>traditional video calling</u>
          </span>
          ?
        </p>

        {MODULES.map((module) => (
          <div tabIndex={0} className="expandable-module" key={module.title}>
            {module.comingSoon ? (
              <span className="coming-soon handwritten blue">Coming soon!</span>
            ) : null}
            {module.later ? (
              <span className="coming-soon handwritten blue">Coming later</span>
            ) : null}
            <span className="blue handwritten keycon">✓</span>
            <img alt="" src={module.icon} />
            <span className="light big-text">{module.title}</span>
            <span
              className="red handwritten trad-calling"
              style={{ top: module.tradCalling === "✓" ? 7 : undefined }}
            >
              {module.tradCalling}
            </span>
            {/* <div className="module-description">
              <p>{module.description}</p>
            </div> */}
          </div>
        ))}
      </div>

      <div id="setup-wrapper" className="hidden pane">
        <div className="for-tutors">
          <span className="handwritten red">For piano tutors...</span>
          <img src={wave} alt="" />
          <p className="med-text">
            Lower the starting barrier for potential new students
          </p>
        </div>
        <div className="for-students">
          <span className="handwritten blue">...and your students</span>
          <img src={cake} alt="" />
          <p className="med-text">
            Easy, affordable setup for aspiring pianists
          </p>
        </div>
        <p className="light handwritten question">
          New students who want{" "}
          <span className="red logo">
            voice <img className="red icon" src={mic} alt="" />
          </span>{" "}
          <u>and</u>{" "}
          <span className="red logo">
            piano <img className="red icon" src={piano} alt="" />
          </span>{" "}
          over a video call...
        </p>

        <div id="old-setup-wrapper">
          <p className="red big-text script">
            ...have three expensive and technically challenging setup options.{" "}
            <span className="big-text handwritten">X</span>
          </p>
          <div>
            <div className="setup-option">
              <p className="red med-text script">MIDI controller</p>

              <p className="red med-text script">DAW + piano VST</p>

              <p className="red med-text script">Routing software</p>
              <p className="red handwritten">
                $<s>200</s>
              </p>
            </div>
            <div className="setup-option">
              <p className="red med-text script">Digital piano</p>

              <p className="red med-text script">Audio interface</p>

              <p className="red med-text script">Routing software</p>
              <p className="red handwritten">
                $<s>600</s>
              </p>
            </div>
            <div className="setup-option">
              <p className="red med-text script">Acoustic piano</p>

              <p className="red med-text script">High quality mic</p>

              <p className="red med-text script">Room treatment</p>
              <p className="red handwritten">
                $<s>3000</s>
              </p>
            </div>
          </div>
          <p className="light handwritten question">
            Or, with{" "}
            <span className="blue logo">
              Keyboard Connect <img src={darkLogoBlue} alt="" /> ✓
            </span>
          </p>
          <div>
            <div
              className="blue setup-option"
              style={{ position: "relative", top: 20 }}
            >
              <p className="blue med-text script">MIDI controller</p>
              <p className="handwritten">$60</p>
            </div>
            <p className="blue handwritten" id="drawn-diagram">
              <img
                src={laptopConnection}
                alt="A midi keyboard plugged into a laptop"
                style={{ position: "relative", right: 30, bottom: 10 }}
              />
              Step 1.&#41; Plug in midi controller
            </p>
          </div>
        </div>
      </div>
      <div id="camera-setup-wrapper" className="hidden pane">
        <p className="light handwritten question">
          Show both{" "}
          <span className="red">
            yourself playing <img src={playing} alt="" />
          </span>{" "}
          <u>and</u> a{" "}
          <span className="blue">
            top-down piano <img src={topDown} alt="" />
          </span>{" "}
          shot?
        </p>

        <div>
          <div className="setup-option">
            <p className="red med-text script">Two cameras</p>
            <p className="red med-text script">Mounting equipment</p>
            <p className="red med-text script">OBS/USB switcher</p>
            <p className="red handwritten">
              $<s>150</s>
            </p>
          </div>
          <p className="handwritten big-text">Or</p>

          <div id="camera-keycon">
            <div className="blue setup-option">
              <p className="blue med-text script">A webcam</p>
              <p className="handwritten">$0 - $40</p>
            </div>

            <p className="blue handwritten">
              With Keyboard Connect
              <img src={darkLogoBlue} alt="" />
            </p>
          </div>
        </div>
      </div>

      <div id="cta-wrapper" className="hidden">
        <div className="cta">
          <p className="light handwritten">
            Ready to try{" "}
            <span className="blue logo">
              Keyboard Connect
              <img src={darkLogoBlue} />
            </span>
            ?
          </p>
          <a
            href="/api/.ory/self-service/registration/browser"
            className="button-like big-text"
          >
            Get started
          </a>
          <p className="light handwritten">And create an account!</p>
        </div>
        <div className="cta">
          <p className="light handwritten">
            Are you a <span className="red">piano tutor</span>?
          </p>
          <div>
            <a
              href="mailto:jeremy@keyboardconnect.com"
              className="button-like big-text"
            >
              Email
              <div className="tooltip">jeremy@keyboardconnect.com</div>
            </a>
            <p className="handwritten light">or</p>
            <a
              href="https://calendly.com/keyboardconnect/30min"
              className="button-like big-text"
            >
              Schedule
              <div className="tooltip">a 30 minute call</div>
            </a>
          </div>
          <p className="light handwritten">And tell me about your work!</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
