import React, { useEffect } from "react";
import logo from "../../../assets/dark-logo-blue.svg";
import "./LoadingScreen.scss";

const LoadingScreen: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      const loadingScreen = document.getElementById("loading-screen-wrapper");
      if (loadingScreen) {
        loadingScreen.classList.remove("hidden");
      }
    }, 200);
  }, []);

  return (
    <div id="loading-screen-wrapper" className="hidden">
      <span id="loading-screen" />
      <p className="blue handwritten">
        Keyboard Connect <img src={logo} alt="" />
      </p>
    </div>
  );
};

export default LoadingScreen;
