import fileSharing from "./assets/file-sharing.svg";
import lessonPlanning from "./assets/lesson-planning.svg";
import metronome from "./assets/metronome2.svg";
import videoCall from "./assets/video-call.svg";
import playPause from "./assets/play-pause.svg";
import midiSharing from "./assets/midi-sharing.svg";

export const MODULES = [
  {
    title: "Video Calling",
    icon: videoCall,
    description: "Video call with your students.",
    tradCalling: "✓",
  },
  {
    title: "MIDI Sharing",
    icon: midiSharing,
    description: "Share MIDI with your students.",
    tradCalling: "x",
  },
  {
    title: "File Sharing",
    icon: fileSharing,
    description: "Share files with your students and other teachers.",
    tradCalling: "~",
    comingSoon: true,
  },
  {
    title: "Lesson Planning",
    icon: lessonPlanning,
    description: "Plan lessons and share them with your students.",
    tradCalling: "x",
    later: true,
  },
  {
    title: "Practice Tools",
    icon: metronome,
    description: "Use practice tools to help your students improve.",
    tradCalling: "x",
  },
  {
    title: "Record Playback",
    icon: playPause,
    description: "Record and playback your students' performances.",
    tradCalling: "x",
    later: true,
  },
];
